import React from 'react';

import Layout from '../components/layout';
import { Content, Footer, FooterLinkNext, Header, Page } from '../components/page';
import Seo from '../components/seo';
import { clearUserData, getUserData } from '../utils/userData';

export default class Logout extends React.Component {
  render() {
    if (getUserData()) clearUserData()

    return (
      <Layout>
        <Seo title="LOG OFF SUCCESSFUL" />

        <Page type="success">
          <Header>logging off</Header>
          <Content center={true}>
            <h1 className="h2 growing text-transform-uppercase">
              YOU'VE LOGGED OUT. SEE&nbsp;YOU NEXT&nbsp;TIME!
            </h1>
            <div className="page-text-container">
              <p className="h6">
                That was fun, right? <br />
                Come back soon to learn more about the original golden lager,
                and keep working toward your CZECH BEER AMBASSADOR
                Certificate!
              </p>
            </div>
          </Content>
          <Footer>
            <FooterLinkNext to="/login">back to log in</FooterLinkNext>
          </Footer>
        </Page>
      </Layout>
    )
  }
}
